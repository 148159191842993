import { envKeys } from 'config';
import { useQuery } from 'react-query';
import { HTTPMethod, HttpRequest } from 'utils/apiUtils';
import { useAuth } from './useAuth';

const fetchAnalytics = async () => {
  const apiKey = localStorage.getItem('apiKey') || '';
  const bearer = `Bearer ${  localStorage.getItem('token')}`;
  const request: HttpRequest = {
    method: HTTPMethod.GET,
    headers: new Headers({ 'content-type': 'application/json', 'apikey': apiKey, 'Authorization': bearer }),
  };
  const response = await fetch(`${envKeys.api}/customer/registry/all`, request).then(res => res.json());
  
  return response;
};

const useAnalytics = ({ enabled = true }: { enabled?: boolean }) => {
  const { validToken } = useAuth();

  return useQuery(['analytics'], 
    () => fetchAnalytics(), 
    { enabled: validToken && enabled },
  );
};

export { useAnalytics };