import { ResponsivePie } from '@nivo/pie';

const dataExample = [
  {
    'id': 'NFT Minted',
    'label': 'NFT Minted',
    'value': 20,
    'color': '#5626EE',
  },
  {
    'id': 'Mints left',
    'label': 'Mints left',
    'value': 80,
    'color': '#E0D9F3',
  },
];

const CenteredMetric = ({ dataWithArc, centerX, centerY }:{ dataWithArc: any, centerX: any, centerY: any }) => {
  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: '30px',
        fontWeight: 600,
      }}
    >
      {dataWithArc[0].value}/{dataWithArc[0].value + dataWithArc[1].value }
    </text>
  );
};

const CircleChart = ({ data = dataExample }: { data?:any }) => (
  <ResponsivePie
    data={data}
    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
    innerRadius={0.7}
    padAngle={0.7}
    cornerRadius={3}
    colors={{ datum: 'data.color' }}
    activeOuterRadiusOffset={8}
    borderWidth={1}
    borderColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          0.1,
        ],
      ],
    }}
    arcLinkLabelsSkipAngle={10}
    arcLinkLabelsTextColor="#333333"
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: 'color' }}
    arcLabelsSkipAngle={10}
    arcLabelsTextColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          2,
        ],
      ],
    }}
    defs={[
      {
        id: 'dots',
        type: 'patternDots',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ]}
    fill={[
      {
        match: {
          id: 'Mints left',
        },
        id: 'lines',
      },
    ]}
    legends={[
      {
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: 56,
        itemsSpacing: 0,
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: '#000',
        itemDirection: 'left-to-right',
        itemOpacity: 1,
        symbolSize: 18,
        symbolShape: 'circle',
      },
    ]}
    layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
  />
);
export default CircleChart;