// Material UI
import EmptyStateCardHorizontal from 'components/business/EmptyStateCardHorizontal';
import { envKeys } from 'config';
import usePageTracking from 'hooks/usePageTracking';
// Components
// Images
import React from 'react';
// Crypto
// Internal
// Styles

interface FormProps {}

// eslint-disable-next-line max-len
const title = 'We will redirect you to the API Docs section';
const subtitle = 'In the Developer Docs section you will be able to learn how to use our APIs and products step by step. The APIs can also be tested right from that section.';
const url = envKeys.stoplight.base;
const pageTitle = 'DOCUMENTATION';

const Documentation: React.FC<FormProps> = () => {
  usePageTracking();

  return ( <EmptyStateCardHorizontal title={title} subtitle={subtitle} url={url} pageTitle={pageTitle}/> );
};

export default Documentation;
