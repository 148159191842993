// Shared Components
import { Box } from '@mui/system';
import { CustomCard, CustomText } from 'components/shared';
// Types
import CircleChart from 'components/shared/CircleChart';
// Hooks
import React from 'react';
// Styles
import { FontSize } from 'styles';

// Utils

interface CardWithImageProps {
  data: any
  title?:string
  parsed?:boolean
}

const parseDataPai = (data: any)=>{
  const yearMonth = `${new Date().getFullYear()}.${new Date().getMonth()}`;
  const mintedNr = data?.find((e:any)=>e.action === 'mint' && yearMonth === e.yearMonth)?.numberOfCalls || 0;

  return ([
    {
      'id': 'NFT Minted',
      'label': 'NFT Minted',
      'value': mintedNr,
      'color': '#5626EE',
    },
    {
      'id': 'Mints left',
      'label': 'Mints left',
      'value': 100 - mintedNr,
      'color': '#E0D9F3',
    },
  ]);
};

const DashboardCardPie: React.FC<CardWithImageProps> = ({ data, title, parsed = false }) => {
  const parsedData = data ? parseDataPai(data) : [];

  return (
    <CustomCard size="medium" style={{ padding: 20, gap: 12, display: 'flex', flexDirection: 'column', minWidth: '500px' }}>
      <CustomText fontWeight={500} fontSize={FontSize.regular}>
        {title}
      </CustomText>
      <Box height={300}>
        <CircleChart data={parsed ? data : parsedData} />
      </Box>
    </CustomCard>
  );
};

export default DashboardCardPie;
