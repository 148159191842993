// Material UI
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, IconButton, Modal, Skeleton, Snackbar } from '@mui/material';
import { useApiKey } from 'api/hooks/useApiKey';
import useRest from 'api/hooks/useRest';
import EmptyStateCardHorizontal from 'components/business/EmptyStateCardHorizontal';
import TextFieldApiKey from 'components/business/TextFieldApiKey';
import { CustomCard, CustomText, Flex } from 'components/shared';
import { envKeys } from 'config';
import { IconCopy, IconInformation } from 'Images';
// Crypto
// Internal
// Styles
// Components
// Images
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Colors, FontFamily, FontSize, FontWeight } from 'styles';
import { HTTPMethod } from 'utils/apiUtils';

import BaseStyles from './style';
import usePageTracking from 'hooks/usePageTracking';

interface User {
  apiKey: string;
  email: string;
  name: string;
  updatedAt: string;
}

// eslint-disable-next-line max-len
const title = 'Create your free API Key';
const subtitle = 'First of all, you need to create your api key for free. Then you can start accessing to the benefits of WakeUp Platform.';
const url = 'https://wakeuplabs.stoplight.io/docs/wakeup-platform';
const pageTitle = 'API KEY';

const GetApiKey: React.FC = () => {
  const { user } = useAuth0();
  const { create } = useParams();
  const [createCustomer, setCreateCustomer] = useState(false);
  const [success, setSuccess] = useState(false);
  const [renewApiKey, setRenewApiKey] = useState(false);
  const [error, setError] = useState('');
  const [firstScreen, setFirstScreen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  usePageTracking();
  const { isLoading: loadingData, data: data, error: errorData } = useApiKey({ enabled: true });
  const { data: dataCreateCustomer, loading: loadingCreate, error: errorCreate } = useRest(`${envKeys.api}/customer`, 
    { enabled: createCustomer, method: HTTPMethod.POST },
    { email: user?.email, name: user?.name },
  );

  const {
    data: dataRenewApi, error: errorRenew,
  } = useRest(`${envKeys.api}/customer/renew-api-key`, 
    { enabled: renewApiKey, method: HTTPMethod.PUT },
    { email: user?.email },
  );

  const getApiKeyInfo = () => {
    let result: User;

    if (dataRenewApi) {
      result = dataRenewApi as User;
    }

    if (dataCreateCustomer) {
      result = dataCreateCustomer as User;
    }

    result = data as User;

    return {
      apiKey: result?.apiKey,
      updatedAt: result?.updatedAt,
    };
  };
  
  const { apiKey, updatedAt } = getApiKeyInfo();
  
  useEffect(()=>{
    setRenewApiKey(false);
  }, [dataRenewApi]);

  useEffect(()=>{
    if (!loadingData && !data && !create) {
      setFirstScreen(true);
    } else {
      setCreateCustomer(false);
    }
  }, [loadingData, data, create]);

  useEffect(()=>{
    if (create === 'true') {
      setCreateCustomer(true);
    }
  }, [create]);

  useEffect(()=>{
    if (errorData) {
      setError('Error getting Api Key info');
      console.error(errorData);
    } else if (errorCreate) {
      setError('Error creating user');
      console.error(errorCreate);
    } else if (errorRenew) {
      setError('Error reseting api key');
      console.error(errorCreate);
    }
  }, [errorData, errorCreate, errorRenew]);

  if (!loadingData && firstScreen) {
    return (
      <EmptyStateCardHorizontal
        title={title}
        subtitle={subtitle}
        url={url}
        pageTitle={pageTitle}
        buttonText='Create API Key'
        onClick={()=>{setFirstScreen(false);setCreateCustomer(true);}}
      />
    );
  }

  return (
    <Box paddingTop="32px" display='flex' width='fit-content' margin='auto' flexDirection='column' alignItems='center' >
      <CustomText
        fontWeight={700}
        fontSize={FontSize.xxxxlarge}
        style={{ alignSelf: 'flex-start' }}
        fontFamily={FontFamily.primary}
      >
        {pageTitle}
      </CustomText>
      <br/>

      {(loadingData || loadingCreate) && (
        <Skeleton sx={{ borderRadius: '10px' }} variant="rectangular" width='700px'  height={200} />
      )}

      {!firstScreen && apiKey && !(loadingData || loadingCreate) &&
        <CustomCard size="big" style={BaseStyles.cardContainer}>
          <Box width='100%'>
            <Flex styles={{ flexDirection: 'row', gap: '8px', marginBottom: 2, width: '100%' }}>
              <TextFieldApiKey value={apiKey} />
              <IconButton sx={BaseStyles.iconButton} onClick={()=>{navigator.clipboard.writeText(apiKey);setSuccess(true);}}>
                <IconCopy />
              </IconButton>
              <LoadingButton sx={BaseStyles.resetButton} variant="outlined" loading={renewApiKey} onClick={()=>setModalOpen(true)} >
              Reset
              </LoadingButton>
            </Flex>
            <CustomText fontSize={FontSize.xxsmall} style={{ marginTop: '4px' }} >
            Created At
              {new Date(updatedAt).toLocaleDateString('en-US',
                { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' },
              )}
            </CustomText>
          </Box>
          <CustomText style={{ width: '90%' }}  fontSize={FontSize.small} fontWeight={FontWeight.medium}>
            You can copy your API Key whenever you want and use it in authorization instances, for example, to mint an NFT.
          </CustomText>
          <div onClick={()=>window.open(envKeys.stoplight.minter, '_blank')}>
            <Flex styles={BaseStyles.footerCardContainer} >
              <IconInformation/>
              <CustomText 
                fontWeight={FontWeight.medium}
                fontSize={FontSize.small}
                style={{ color: Colors.violetMain }}
              >
                  How to use the API key?
              </CustomText>
            </Flex>
          </div>
        </CustomCard>
      }
      {success && 
        <Snackbar anchorOrigin={ { horizontal: 'right', vertical: 'top' } } open={success} autoHideDuration={2000} onClose={()=>setSuccess(false)}>
          <Alert onClose={() => {setSuccess(false);}} severity="success">Copied to clipboard!</Alert>
        </Snackbar>
      }
      {error && 
        <Snackbar anchorOrigin={ { horizontal: 'right', vertical: 'top' } } open={error.length > 0} autoHideDuration={6000} onClose={()=>setError('')}>
          <Alert onClose={() => {setError('');}} severity="error">{error || 'Somenthing went wrong, please try again.'}</Alert>
        </Snackbar>
      }
      <Modal
        open={modalOpen}
        onClose={()=>setModalOpen(false)}
      >
        <Box style={BaseStyles.modalContainer} >  
          <CustomText fontSize={FontSize.large} fontWeight={FontWeight.medium}>
            Are you sure you want to reset your API Key?
          </CustomText>
          <Box>
            <LoadingButton sx={{ ...BaseStyles.resetButton, marginRight: '12px' }} variant="outlined" loading={renewApiKey} onClick={()=>setModalOpen(false)} >
              Cancel
            </LoadingButton>
            <LoadingButton sx={BaseStyles.resetButton} variant="contained" loading={renewApiKey} onClick={()=>{setRenewApiKey(true); setModalOpen(false);localStorage.setItem('apikey', '');}} >
              Reset
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
        
};

export default GetApiKey;
