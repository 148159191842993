// Internal components
import Subscription from './Subscription';

const paths = {
  root: '/',
};

export const routes = [
  {
    path: paths.root,
    Page: Subscription,
    exact: true,
    responsive: false,
  },
];