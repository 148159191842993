const styles = {
  root: {
    background: 'white',
    height: '98vh',
    overflow: 'hidden',
  },
  background1: {
    height: '405px',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    top: '0px',
  },
  background2: {
    backgroundImage: 'url(Images/Landing2)',
    height: '259px',
    position: 'absolute',
    width: '473px',
    backgroundRepeat: 'no-repeat',
    bottom: '0px',
    right: '0px',
  },
};

export default styles;
