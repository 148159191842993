import { useAuth0 } from '@auth0/auth0-react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/system';
import { useApiKey } from 'api/hooks/useApiKey';
import { useUploadedFiles } from 'api/hooks/useUploadFiles';
import { CustomText, Flex, Loading } from 'components/shared';
import { FontFamily, FontSize } from 'styles';

export default function TableUploadedFiles() {
  const { isLoading: loadingFiles, data: uploadedFiles } = useUploadedFiles({});
  const { isLoading: loadingApiKey, data: customerData } = useApiKey({});

  if ((loadingFiles || loadingApiKey) && !!customerData) {
    return (
      <Flex >
        <Loading />
      </Flex>
    );
  }

  return (
    <Box>
      <CustomText 
        fontWeight={700}
        fontSize={FontSize.xxxxlarge}
        style={{ alignSelf: 'flex-start', marginBottom: '16px' }}
        fontFamily={FontFamily.primary}
      >
        Uploaded Files
      </CustomText>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} >
          <TableHead>
            <TableRow>
              <TableCell >File Name</TableCell>
              <TableCell>IPFS URI</TableCell>
              <TableCell>File Size</TableCell>
              <TableCell align="center">Uploaded date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customerData && 
            (uploadedFiles as Array<any>)?.map(row => (
              <TableRow key={row.name} >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  <a href={row.uploadedFileUrl} target='_blank'>{row.uploadedFileUrl}</a>
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.size.toLocaleString()} KB
                </TableCell>
                <TableCell align="center">{new Date(row.createdAt).toLocaleDateString()}</TableCell>
              </TableRow>
            ))}
            {((uploadedFiles as Array<any>)?.length === 0 || !customerData) && 
          <TableRow  >
            <TableCell colSpan={4} component="th" scope="row" sx={{ textAlign: 'center' }}>
             No uploaded files found. Please upload some from the API to see all its information here
            </TableCell>
          </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
