import axios from 'axios';

interface AuthenticateProps {
  baseUrl: string;
  clientId: string;
  userId: string;
  redirectUri: string;
}
export const authenticate = ({
  baseUrl,
  clientId,
  userId,
  redirectUri,
}: AuthenticateProps): void => {
  const url = `${baseUrl}?client_id=${clientId}&response_type=code&platform_id=mp&state=${userId}&redirect_uri=${redirectUri}`;
  window.open(url, '_self');
};

interface GetTokenProps {
  baseUrl: string;
  code: string;
  userId: string;
}

export const getToken = async ({
  baseUrl,
  code,
  userId,
}: GetTokenProps): Promise<Record<string, string> | null> => {
  try {
    // Fetch data from external API
    const response = await axios.post(
      `${baseUrl}/payment/connect`,
      {
        code,
        userId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response.data;
  } catch (err) {
    return null;
  }
};
