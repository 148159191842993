import { Colors } from 'styles';

const BaseStyles = {
  root: {
    alignItems: 'flex-start',
    width: '100%',
    gap: 24,
  },
  container: {
    gap: 24,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flex: 1,
  },
  iconButton: {
    width: 34,
    height: 34,
    marginLeft: '12px',
    backgroundColor: Colors.violetSecond,
    borderRadius: '8px',
    '&:hover': {
      background: Colors.violetSecond, 
    },
  },
};

export default BaseStyles;
