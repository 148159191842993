import { minHeightTobBar } from 'styles/constants';

const BaseStyles = {
  root: {
    width: '100%',
    minHeight: `calc(100vh - ${minHeightTobBar})`,
    background: '#F5F5F5 !important',
  },
  rootWithoutLayout: {
    width: '100%',
    padding: 16,
    backgroundColor: '#F5F5F5 !important',
  },
};

export default BaseStyles;
