import { Colors } from 'styles';

const BaseStyles: Styles = {
  cardContainer: {
    padding: 32, 
    gap: 12,
    display: 'flex', 
    flexDirection: 'column',
    width: 'fit-content',
    alignSelf: 'flex-start', 
    alignItems: 'flex-start',
  },
  iconButton: {
    width: 56,
    height: 56,
    backgroundColor: Colors.violetSecond,
    borderRadius: '8px',
    '&:hover': {
      background: Colors.violetSecond, 
    },
  },
  copyButton: {
    width: 56, 
    height: 56,
    border: `1px solid ${Colors.violetSecond}`,
  },
  resetButton: {
    width: 175, 
    height: 56,
    border: `1px solid ${Colors.violetSecond}`,
  },
  footerCardContainer: {
    flexDirection: 'row',
    marginTop: '4px',
    gap: '4px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: Colors.violetMain,
    cursor: 'pointer',
  },
  modalContainer: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    background: 'white',
    boxShadow: 24,
    padding: 4,
    height: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '8px',
  },
};
  
export default BaseStyles;
  