// Layout
import layout from 'components/business/layout';
import Analytics from 'pages/Analytics';
import AssetManagement from 'pages/AssetManagement';
import DeployedContracts from 'pages/DeployedContracts';
import DeployedERC20 from 'pages/DeployedERC20/DeployedERC20';
import Documentation from 'pages/Documentation';
import GetApiKey from 'pages/GetApiKey';
// Pages
import Home from 'pages/Home';
import Login from 'pages/Login';
import Marketplace from 'pages/Marketplace';
import MintNFT from 'pages/MintNFT';
import Checkout from 'pages/Payments/Checkout';
import Rental from 'pages/Rental';
import Subscription from 'pages/Subscription';
import UploadedFiles from 'pages/UploadedFiles';

// Pages
export enum BackgroundEnum {
  gray = 'gray',
  difuminated = 'difuminated',
}

const simplePaths = {
  // Generics
  empty: '/',
  home: '/',
  getApiKey: '/getApiKey',
  documentation: '/documentation',
  mintNFT: '/mint',
  uploadedFiles: '/uploadedFiles',
  subscription: '/subscription',
  deployedContracts: '/deployedContracts',
  deployedErc20: '/deployedErc20',
  swap: '/swap',
  renting: '/renting',
  analytics: '/analytics',
  assetManagement: '/assetManagement',
  login: '/login',
  checkout: '/checkout',
};

type RedirectObject = {
  [key in keyof typeof simplePaths]: (param?:string) => string;
};

const redirect: RedirectObject = {
  empty: () => simplePaths.empty,
  home: () => simplePaths.home,
  documentation: () => simplePaths.documentation,
  mintNFT: () => simplePaths.mintNFT,
  uploadedFiles: () => simplePaths.uploadedFiles,
  deployedContracts: () => simplePaths.deployedContracts,
  deployedErc20: () => simplePaths.deployedErc20,
  subscription: () => simplePaths.subscription,
  getApiKey: (create = 'false') => `${simplePaths.getApiKey}/${create}`,
  swap: () => simplePaths.swap,
  renting: () => simplePaths.renting,
  analytics: () => simplePaths.analytics,
  assetManagement: () => simplePaths.assetManagement,
  login: () => simplePaths.login,
  checkout: () => simplePaths.checkout,
};
const routes: Routes = {
  public: [
    {
      path: simplePaths.home,
      label: 'Home',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.gray,
      Page: Home,
      exact: true,
      responsive: false,
    },
    {
      path: simplePaths.login,
      label: 'Login',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.gray,
      Page: Login,
      exact: true,
      responsive: true,
    },
    
  ],
  private: [
    {
      path: simplePaths.checkout,
      label: 'Checkout',
      isShownInTab: true,
      Layout: layout,
      Background: BackgroundEnum.gray,
      Page: Checkout,
      exact: true,
      responsive: true,
    },
    {
      path: simplePaths.getApiKey,
      label: 'ApiKey',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.gray,
      Page: GetApiKey,
      exact: false,
      responsive: false,
    },
    {
      path: simplePaths.documentation,
      label: 'Documentation',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.gray,
      Page: Documentation,
      exact: true,
      responsive: false,
    },
    {
      path: simplePaths.mintNFT,
      label: 'MintNFT',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.gray,
      Page: MintNFT,
      exact: true,
      responsive: false,
    },
    {
      path: simplePaths.uploadedFiles,
      label: 'Uploaded Files',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.gray,
      Page: UploadedFiles,
      exact: true,
      responsive: false,
    },
    {
      path: simplePaths.deployedContracts,
      label: 'Deployed Contracts',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.gray,
      Page: DeployedContracts,
      exact: true,
      responsive: false,
    },
    {
      path: simplePaths.deployedErc20,
      label: 'Deployed ERC20',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.gray,
      Page: DeployedERC20,
      exact: true,
      responsive: false,
    },
    {
      path: simplePaths.subscription,
      label: 'Subscription',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.gray,
      Page: Subscription,
      exact: true,
      responsive: false,
    },
    {
      path: simplePaths.renting,
      label: 'Documentation',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.gray,
      Page: Rental,
      exact: true,
      responsive: false,
    },
    {
      path: simplePaths.analytics,
      label: 'Documentation',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.gray,
      Page: Analytics,
      exact: true,
      responsive: false,
    },
    {
      path: simplePaths.swap,
      label: 'Documentation',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.gray,
      Page: Marketplace,
      exact: true,
      responsive: false,
    },
    {
      path: simplePaths.assetManagement,
      label: 'Asset Management',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.gray,
      Page: AssetManagement,
      exact: true,
      responsive: false,
    },
  ],
};

// TODO: make it better with connection state
const tabRoutes = [...routes.public, ...routes.private].filter(route => route.isShownInTab);

export { redirect, routes, tabRoutes };
