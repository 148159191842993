import React from 'react';
import { envKeys } from 'config';
import { useQuery } from 'react-query';
import { HTTPMethod, HttpRequest } from 'utils/apiUtils';
import { useAuth } from './useAuth';
import { useAuth0 } from '@auth0/auth0-react';

const fetchApiKey = async (token: string) => {
  const bearer = `Bearer ${token}`;
  const request: HttpRequest = {
    method: HTTPMethod.GET,
    headers: new Headers({ 'content-type': 'application/json', 'Authorization': bearer }),
  };
  const response = await fetch(`${envKeys.api}/customer`, request).then(res => res.json());
  localStorage.setItem('apiKey', response?.apiKey || '');
  
  return response;
};

const useApiKey = ({ enabled = true }: { enabled?: boolean }) => {
  const { validToken } = useAuth();
  const { getAccessTokenSilently } = useAuth0();

  const response = useQuery([`apiKey-${validToken}`], 
    async () => {
      const token = await getAccessTokenSilently();
      
      return fetchApiKey(token);
    }, 
    { enabled: enabled },
  );

  React.useEffect(() => {
    if (!response.data && validToken) {
      response.refetch();
    }
  }, [response, validToken]);

  return response;
};

export { useApiKey };