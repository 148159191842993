import { useAuth0 } from '@auth0/auth0-react';
// Wagmi

interface UseUserProps {
  isConnected: boolean;
}

const useUser = (): UseUserProps => {
  const { isAuthenticated, isLoading } = useAuth0();

  return {
    isConnected: isAuthenticated && !isLoading,
  };
};

export default useUser;
