import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

const useAuth = () => {
  const [validToken, setValidToken] = useState(true);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const isValidToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        localStorage.setItem('token', token);
      } catch (e: unknown) {
        setValidToken(false);
        console.error('Error fetching auth:', e);
      }
    };

    isValidToken();
  }, [getAccessTokenSilently]);

  return { validToken };
};

export { useAuth };