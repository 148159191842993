// Material
import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Box, Container, Divider, Drawer, List, ListItem, ListItemButton, Toolbar } from '@mui/material';
import { redirect } from 'components/routes/config';
import { CustomText, Flex } from 'components/shared';
import { ComingSoonIcon, ComingSoonIconLight, DashboardIcon, DashboardSelected, DocsIcon, KeyIcon, KeySelected, NFTIcon, NFTSelected, NoteSelected, SwapIcon, ArrowLeft } from 'Images';
import WakeUpLogo from 'Images/WakeUpLogo.svg';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Colors } from 'styles';
import { Payment, PaymentOutlined } from '@mui/icons-material';
import Navigation from './navigation/Navigation';

const stylesSelected = {
  list: { background: Colors.lightGray, borderRadius: '12px 0 0 12px' },
  text: { color: 'black', marginLeft: '8px' },
};

enum MenuItems {
  '/' = 0,
  '/getApiKey/' = 1,
  '/getApiKey/true' = 1,
  '/subscription' = 6,
  '/uploadedFiles' = 4,
  '/deployedContracts' = 5,
  '/mint' = 2,
  '/documentation' = 3,
  '/swap' = 7,
  '/renting' = 8,
  '/analytics' = 9,
  '/assetManagement' = 10,
  '/checkout' = 11,
}

const TopBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth0();
  const [selectedOption, setSelectedOption] = useState(MenuItems[pathname.toString() as (keyof typeof MenuItems)] || 0);

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" elevation={0} sx={{ background: Colors.greyDarker }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
            {!isAuthenticated && 
             <Flex>
               <img
                 src={WakeUpLogo}
                 alt="wakeupLogo"
                 onClick={() => navigate(redirect.home())}
                 style={{ cursor: 'pointer', width: 100, backgroundColor: '#2c2c2c' }}
               />
             </Flex>
            }
            <Navigation />
          </Toolbar>
        </Container>
      </AppBar>
      {isAuthenticated &&
        <Drawer
          variant="permanent"
          color='#2c2c2c'
          sx={{
            flexShrink: 1,
            ['& .MuiDrawer-paper']: { width: 220, boxSizing: 'border-box', backgroundColor: '#2c2c2c', borderRight: 0 },
          }}
        >
          <Toolbar sx={{ background: '#2c2c2c' }}>
            <Flex>
              <img
                src={WakeUpLogo}
                alt="wakeupLogo"
                onClick={() => navigate(redirect.home())}
                style={{ cursor: 'pointer', width: 100, backgroundColor: '#2c2c2c' }}
              />
            </Flex>
          </Toolbar>
          <Box sx={{ overflow: 'auto', backgroundColor: '#2c2c2c' }}>
            <Divider sx={{ color: 'white' }} />
            <List sx={{ padding: '12px 0 12px 0' }}>
              <ListItem sx={selectedOption === 0 ? stylesSelected.list : {}} key='Dashboard' disablePadding onClick={()=>{navigate(redirect.home()); setSelectedOption(0);}}>
                <ListItemButton>
                  {selectedOption === 0 ? <DashboardSelected/> : <DashboardIcon /> }
                  <CustomText style={selectedOption === 0 ? stylesSelected.text : { color: 'white', marginLeft: '8px' }}>Dashboard</CustomText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={selectedOption === 1 ? stylesSelected.list : {}} key='ApiKey' disablePadding onClick={()=>{navigate(redirect.getApiKey('')); setSelectedOption(1);}}>
                <ListItemButton>
                  {selectedOption === 1 ? <KeySelected /> : <KeyIcon /> }
                  <CustomText style={selectedOption === 1 ? stylesSelected.text : { color: 'white', marginLeft: '8px' }} >Api Key</CustomText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={selectedOption === 6 ? stylesSelected.list : {}} key='Subscription' disablePadding onClick={()=>{navigate(redirect.subscription()); setSelectedOption(6);}}>
                <ListItemButton>
                  {selectedOption === 6 ? <KeySelected /> : <KeyIcon /> }
                  <CustomText style={selectedOption === 6 ? stylesSelected.text : { color: 'white', marginLeft: '8px' }} >Subscription</CustomText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={selectedOption === 11 ? stylesSelected.list : {}} key='Payments' disablePadding onClick={()=>{navigate(redirect.checkout()); setSelectedOption(11);}}>
                <ListItemButton>
                  {selectedOption === 11 ? <Payment /> : <Payment  style={{ color: '#fff' }} /> }
                  <CustomText style={selectedOption === 11 ? stylesSelected.text : { color: 'white', marginLeft: '8px' }} >Payments</CustomText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={selectedOption === 3 ? stylesSelected.list : {}} key='Docs' disablePadding onClick={()=>{navigate(redirect.documentation()); setSelectedOption(3);}}>
                <ListItemButton>
                  {selectedOption === 3 ? <NoteSelected/> : <DocsIcon /> }
                  <CustomText style={selectedOption === 3 ? stylesSelected.text : { color: 'white', marginLeft: '8px' }} >Documentation</CustomText>
                </ListItemButton>
              </ListItem>
            </List>
            <Divider sx={{ color: 'white' }} />
            <List sx={{ padding: '12px 0 12px 0' }}>
              <ListItem sx={selectedOption === 2 ? stylesSelected.list : {}} key='MintNft' disablePadding onClick={()=>{navigate(redirect.mintNFT()); setSelectedOption(2);}}>
                <ListItemButton>
                  {selectedOption === 2 ? <NFTSelected /> : <NFTIcon /> } 
                  <CustomText style={selectedOption === 2 ? stylesSelected.text : { color: 'white', marginLeft: '8px' }} >Tokenization</CustomText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={selectedOption === 7 ? stylesSelected.list : {}} key='MintNft' disablePadding onClick={()=>{navigate(redirect.swap()); setSelectedOption(7);}}>
                <ListItemButton>
                  {selectedOption === 7 ? <SwapIcon height='22px' /> : <SwapIcon filter='invert(1)' height='22px'/> } 
                  <CustomText style={selectedOption === 7 ? stylesSelected.text : { color: 'white', marginLeft: '8px' }} >Swap</CustomText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={selectedOption === 8 ? stylesSelected.list : {}} key='MintNft' disablePadding onClick={()=>{navigate(redirect.renting()); setSelectedOption(8);}}>
                <ListItemButton>
                  {selectedOption === 8 ? <ComingSoonIcon height='22px' /> : <ComingSoonIcon filter='invert(1)' height='22px'/> } 
                  <CustomText style={selectedOption === 8 ? stylesSelected.text : { color: 'white', marginLeft: '8px' }} >Renting</CustomText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={selectedOption === 9 ? stylesSelected.list : {}} key='MintNft' disablePadding onClick={()=>{navigate(redirect.analytics()); setSelectedOption(9);}}>
                <ListItemButton>
                  {selectedOption === 9 ? <ComingSoonIcon height='22px' /> : <ComingSoonIcon filter='invert(1)' height='22px'/> } 
                  <CustomText style={selectedOption === 9 ? stylesSelected.text : { color: 'white', marginLeft: '8px' }} >Analytics</CustomText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={selectedOption === 10 ? stylesSelected.list : {}} key='MintNft' disablePadding onClick={()=>{navigate(redirect.assetManagement()); setSelectedOption(10);}}>
                <ListItemButton>
                  {selectedOption === 10 ? <ComingSoonIcon height='22px' /> : <ComingSoonIcon filter='invert(1)' height='22px'/> } 
                  <CustomText style={selectedOption === 10 ? stylesSelected.text : { color: 'white', marginLeft: '8px' }} >Asset Management</CustomText>
                </ListItemButton>
              </ListItem>
            </List>
            <Divider sx={{ color: 'white' }} />
            <List sx={{ padding: '12px 0 12px 0' }}>
              <ListItem sx={selectedOption === 4 ? stylesSelected.list : {}} key='Docs' disablePadding onClick={()=>{navigate(redirect.uploadedFiles()); setSelectedOption(4);}}>
                <ListItemButton>
                  {selectedOption === 4 ? <NoteSelected /> : <DocsIcon /> }
                  <CustomText style={selectedOption === 4 ? stylesSelected.text : { color: 'white', marginLeft: '8px' }} >Uploaded Files</CustomText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={selectedOption === 5 ? stylesSelected.list : {}} key='Docs' disablePadding onClick={()=>{navigate(redirect.deployedContracts()); setSelectedOption(5);}}>
                <ListItemButton>
                  {selectedOption === 5 ? <NoteSelected /> : <DocsIcon /> }
                  <CustomText style={selectedOption === 5 ? stylesSelected.text : { color: 'white', marginLeft: '8px' }} >Deployed NFTs Contracts</CustomText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={selectedOption === 11 ? stylesSelected.list : {}} key='Docs' disablePadding onClick={()=>{navigate(redirect.deployedErc20()); setSelectedOption(11);}}>
                <ListItemButton>
                  {selectedOption === 11 ? <NoteSelected /> : <DocsIcon /> }
                  <CustomText style={selectedOption === 11 ? stylesSelected.text : { color: 'white', marginLeft: '8px' }} >Deployed ERC20 Contracts</CustomText>
                </ListItemButton>
              </ListItem>
            </List>
          
          </Box>
        </Drawer>
      }
    </Box>
  );
};
export default TopBar;
