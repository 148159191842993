// Contexts
import 'styles/globalStyles.css';

// Apollo
import { useAuth0 } from '@auth0/auth0-react';
// Material
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { routes } from 'components/routes/config';
// Components
import Router from 'components/routes/Router/Router';
import { Loading } from 'components/shared';
// Hooks
import useUser from 'hooks/useUser';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
// Integration
import ReactGA from 'react-ga4';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

const UserRoutes = () => {
  const { isConnected } = useUser();

  const { isLoading } = useAuth0();

  const allRoutes = !isConnected ? routes.public : [...routes.public, ...routes.private];

  if (isLoading) {
    return <Loading />;
  }
  
  return <Router routes={allRoutes} />;
};

const App = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  useEffect(()=>{
    const getToken = async ()=>{
      const token = await getAccessTokenSilently();
      localStorage.setItem('token', token);
    };
    if (isAuthenticated) {
      getToken();
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(()=>{
    if (isAuthenticated) {
      ReactGA.event({
        category: 'login',
        action: 'Succeeded login',
        label: user?.email?.replace('@', '_'),
      });
    }
  }, [isAuthenticated, user?.email]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <UserRoutes />
        </QueryParamProvider>
      </BrowserRouter>
    </LocalizationProvider>
  );
};

export default App;
