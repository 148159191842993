// Internal components
import GetApiKey from './GetApiKey';

const paths = {
  root: '/',
  create: '/:create',
};

export const routes = [
  {
    path: paths.create,
    Page: GetApiKey,
    exact: true,
    responsive: false,
  },
  {
    path: paths.root,
    Page: GetApiKey,
    exact: false,
    responsive: false,
  },
];