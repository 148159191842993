// Material UI
import { Box } from '@mui/system';
import TableDeployedERC20 from 'components/business/TableDeployedERC20/TableDeployedERC20';
import usePageTracking from 'hooks/usePageTracking';
// Components
// Images
import React from 'react';
// Crypto
// Internal
// Styles

const DeployedERC20: React.FC = () => {
  usePageTracking();
  
  return ( 
    <Box padding='32px 0 0 0' width='90%' margin='auto'>
      <TableDeployedERC20 />
    </Box>
  );
};

export default DeployedERC20;
