import { envKeys } from 'config';
import { useQuery } from 'react-query';
import { HTTPMethod, HttpRequest } from 'utils/apiUtils';

const fetchContracts = async () => {
  const apiKey = localStorage.getItem('apiKey') || '';
  const bearer = `Bearer ${  localStorage.getItem('token')}`;
  const request: HttpRequest = {
    method: HTTPMethod.GET,
    headers: new Headers({ 'content-type': 'application/json', 'apikey': apiKey, 'Authorization': bearer }),
  };
  const response = await fetch(`${envKeys.apiMinter}/contract/erc20/list`, request).then(res => res.json());
  
  return response;
};

const useDeployedERC20 = ({ enabled = true }: { enabled?: boolean }) => {
  return useQuery(['erc20Deployed'], 
    () => fetchContracts(), 
    { enabled: !!localStorage.getItem('apiKey') && enabled },
  );
};

export { useDeployedERC20 };