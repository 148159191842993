// Material UI
import EmptyStateCardHorizontal from 'components/business/EmptyStateCardHorizontal';
import { envKeys } from 'config';
import usePageTracking from 'hooks/usePageTracking';
// Components
// Images
import React from 'react';
// Crypto
// Internal
// Styles

interface FormProps {}

// eslint-disable-next-line max-len
const title = 'Coming Soon';
const subtitle = 'This feature is still not available for all users. Please complete the form to request access.';
const url = 'https://meetings-eu1.hubspot.com/maximiliano-del-hoyo';
const pageTitle = 'ASSET MANAGEMENT';

const AssetManagement: React.FC<FormProps> = () => {
  usePageTracking();
  
  return ( 
    <EmptyStateCardHorizontal 
      title={title}
      subtitle={subtitle}
      url={url}
      buttonText='Request Access'
      pageTitle={pageTitle} />
  );
};

export default AssetManagement;
