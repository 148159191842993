// Material
import { useAuth0 } from '@auth0/auth0-react';
import { Box, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
// Theme
import theme from 'styles/theme';

// Local components
import TopBar from './TopBar';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { isAuthenticated } = useAuth0();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <TopBar />
      <Box sx={{ marginLeft: isAuthenticated ? '200px' : 0, paddingTop: 4 }}>{children}</Box>
    </ThemeProvider>
  );
};

export default Layout;
