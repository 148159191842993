import React from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Hook which define minwidth for those pages which do not have implemented their responsiveness 
 * @param routes 
 */
export const useRouteResponsive = (routes: RouteListProps[]) => {
  const location = useLocation();

  React.useEffect(() => {
    const route = routes.find(item => item.path === location.pathname);

    const root = document.getElementById('root');
  
    if (root && root.style) {
      root.style.minWidth = !route?.responsive ? '1366px' : 'unset';
    }

  }, [location.pathname, routes]);

};