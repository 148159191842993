enum FontFamily {
  primary = 'Oswald',
  secondary = 'Roboto', // TODO: add Oswald
  default = 'Roboto',
}

enum FontSize {
  xxsmall = '0.75rem', // 12px
  xsmall = '0.8125rem', // 13px
  small = '0.875rem', // 14px
  regular = '1rem', // 16px
  large = '1.125rem', // 18px
  xlarge = '1.25rem', // 20px
  xxlarge = '1.375rem', // 22px
  xxxlarge = '1.5rem', // 24px
  xxxxlarge = '1.8rem', // 28px
  xxxxxlarge = '2.5rem', // 40px
  default = '1rem', // 16px
}

enum FontWeight {
  thin = 100,
  extraLight = 200,
  light = 300,
  regular = 400,
  medium = 500,
  bold = 600,
  extraBold = 700,
  default = 400,
}

export { FontFamily, FontSize, FontWeight };
