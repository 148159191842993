import Router from 'components/routes/Router/Router';
import React from 'react';

import { routes } from './routes';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LendProps {
}

const GetApiKey: React.FC<LendProps> = () => {
  return (
    <Router routes={routes} />
  );
};

export default GetApiKey;
