export enum HTTPMethod {
  POST = 'POST',
  GET = 'GET',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH',
}

export interface HttpRequest {
  headers?: CommonJSON;
  body?: string;
  method: HTTPMethod;
  contentType?: string;
}

const buildRequest = (verb: HTTPMethod, data?: CommonJSON, headers = null) => {
  const bearer = `Bearer ${  localStorage.getItem('token')}`;
  const apiKey = localStorage.getItem('apiKey') || '';
  const request: HttpRequest = {
    method: verb,
    headers: new Headers({ 'content-type': 'application/json', 'Authorization': bearer, 'apikey': apiKey }),
  };
  if (headers !== null) {
    request.headers = headers;
  }
  if (verb === HTTPMethod.POST) {
    request.body = JSON.stringify(data);
  } else if (verb === HTTPMethod.PUT) {
    request.body = JSON.stringify(data);
  } else if (verb === HTTPMethod.PATCH) {
    request.body = JSON.stringify(data);
  }

  return request;
};

const createParams = (data: CommonJSON) => {
  const params = Object.entries(data).reduce((acc, [key, value], index) => {
    const result = `${key}=${value}`;
    if (index === 0) {
      return `?${result}`;
    }

    return `${acc}&${result}`;
  }, '');

  return params;
};

export const sendRequest = async (
  url: string,
  params?: CommonJSON,
  method: HTTPMethod = HTTPMethod.GET,
  data?: CommonJSON,
): Promise<CommonJSON | undefined> => {
  const request = buildRequest(method, data);

  const paramsParsed = createParams(params || {});
  const reqUrl = paramsParsed ? `${url}${paramsParsed}` : url;

  const response = await fetch(reqUrl, request);
  if (!response.ok) {
    throw new Error(JSON.stringify(response.body));
  }
  
  return response.json() as CommonJSON;
 
};
