/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';

// Infrastructure
import { MercadoPagoRepository } from './mercadoPago';
import { Button } from '@mui/material';

interface AuthenticationConfig {
  baseUrl: string;
  clientId: string;
  redirectUri: string;
  userId: string;
}

interface MercadoPagoConnectProps {
  mercadoPagoRepository: MercadoPagoRepository;
  authenticationConfig: AuthenticationConfig;
  token?: Record<string, string> | null;
}

const MercadoPagoConnect: React.FC<MercadoPagoConnectProps> = ({
  mercadoPagoRepository,
  authenticationConfig,
  token,
}) => {
  const handleConnect = () => {
    try {
      mercadoPagoRepository.authenticate(authenticationConfig);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="flex-col align-center gap-4" style={{ padding: 50 }}>
      <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
				Mercado Connect
      </h2>
      {token ? (
        <>
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
									Access Token
                </th>
                <th scope="col" className="px-6 py-3">
									Expires
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4">{`Access Token ${token.accessToken}`}</td>
                <td className="px-6 py-4">{`Expires ${new Date(token.expiresDate)}`}</td>
              </tr>
            </tbody>
          </table>
          <p>No es necesario que des acceso nuevamente</p>
          <Button type="button" onClick={handleConnect} size="large">
						Connect again
          </Button>
        </>
      ) : (
        <>
          <p>Es necesario que des acceso</p>
          <Button type="button" onClick={handleConnect} size="large">
						Connect
          </Button>
        </>
      )}
    </div>
  );
};

export default MercadoPagoConnect;
