// Styles
import './Navigation.css';

//Auth0
import { useAuth0 } from '@auth0/auth0-react';
// Utils
// Components
import { Button, CustomText, Flex } from 'components/shared';

// Images
import { StyledRoot } from './style';

export default function Navigation() {
  // Hooks
  const { isAuthenticated, user, logout } = useAuth0();

  return (
    <StyledRoot>
      <Flex row styles={{ width: '100%', justifyContent: 'flex-end' }}>
        {isAuthenticated &&
        <>
          <CustomText>{user?.email}</CustomText>
          <Button sx={{ marginLeft: 1 }} onClick={() => {
            localStorage.setItem('token', '');
            localStorage.setItem('apiKey', ''); 
            logout({ 
              logoutParams: {
                returnTo: window.location.origin,
              },
            });
          }} text='Logout' color="primary" />
        </>
        }
      </Flex>
    </StyledRoot>
  );
}
