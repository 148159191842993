import React, { useState } from 'react';
// Utils
import { HTTPMethod, sendRequest } from 'utils/apiUtils';

interface Options {
  enabled?: boolean;
  method?: HTTPMethod;
  params?: CommonJSON<any>
}

interface UseRestResponse<T> {
  data: T;
  error: RestError | null;
  loading: boolean;
}

const useRest = <DataRestResponse = unknown>(
  url: string | undefined,
  options: Options,
  body?: CommonJSON,
): UseRestResponse<DataRestResponse> => {
  // Refs
  const [loading, setLoading] = useState(true);
  // States
  const [data, setData] = React.useState<CommonJSON | null | undefined>(null);
  const [error, setError] = React.useState<RestError | null>(null);

  // Effects
  React.useEffect(() => {
    const callRequest = async (urlCall: string) => {
      try {
        setLoading(true);
        const res = await sendRequest(urlCall, options.params, options.method, body);
        setData(res);
        setLoading(false);
      } catch (e: unknown) {
        const err = e as RestError;
        setLoading(false);
        setError(err);
      }
    };

    if (options.enabled && url) {
      try {
        void callRequest(url);
      } catch (e) {
        console.error(e);
      }
    } else {
      setLoading(false);
    }
  }, [options.enabled, url]);

  return {
    data: data as DataRestResponse,
    error,
    loading,
  };
};

export default useRest;
