// Material UI
import { Box } from '@mui/system';
import TableDeployedContracts from 'components/business/TableDeployedContracts';
import usePageTracking from 'hooks/usePageTracking';
// Components
// Images
import React from 'react';
// Crypto
// Internal
// Styles

const DeployedContracts: React.FC = () => {
  usePageTracking();
  
  return ( 
    <Box padding='32px 0 0 0' width='90%' margin='auto'>
      <TableDeployedContracts />
    </Box>
  );
};

export default DeployedContracts;
