import React from 'react';
// Material UI
import { useAuth0 } from '@auth0/auth0-react';
import { Button, CustomText } from 'components/shared';
import usePageTracking from 'hooks/usePageTracking';
// Crypto
// Internal
// Styles
import styles from './style';
import LogoBlack from 'Images/logo-black.svg';
import { FontSize, Colors } from 'styles';
import { Link } from '@mui/material';

interface FormProps {}

const Login: React.FC<FormProps> = () => {
  usePageTracking();
  const { handleRedirectCallback, loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    handleRedirectCallback();
  }, [handleRedirectCallback]);
  
  return ( 
    <>
      <div style={styles.root}>
        <div style={{
          display: 'flex',
          margin: 'auto',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center', 
          maxWidth: 400,
        }}>
          <div
            style={{ display: 'flex', margin: 'auto', flexDirection: 'column', gap: 40 }}
          >
            <img src={LogoBlack} width={500} alt="WakeUp logo" />

            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
              <CustomText fontWeight={600} fontSize={FontSize.large} style={{ color: Colors.black }}>
                The WakeUp Platform allows traditional businesses to transition easily to Web3.
              </CustomText>
              <a href='https://www.wakeuplabs.io/' target='_blank'>
                <Link>More Info</Link>
              </a>
            </div>

            <Button
              style={{ margin: 'auto', maxWidth: '30vw', borderRadius: 24 }}
              size="large"
              color="info"
              onClick={async () => {
                try {
                  await loginWithRedirect({
                    authorizationParams: {
                      redirect_uri: window.location.origin,
                    },
                  });
                } catch (e) {
                  console.error(e);
                }
              }}
              text='Login to continue'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
