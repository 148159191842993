// Material UI
import EmptyStateCardHorizontal from 'components/business/EmptyStateCardHorizontal';
import { envKeys } from 'config';
import usePageTracking from 'hooks/usePageTracking';
// Components
// Images
import React from 'react';
// Crypto
// Internal
// Styles

interface FormProps {}

// eslint-disable-next-line max-len
const title = 'We will redirect you to the API Docs section so you can integrate SWAP API';
const subtitle = 'In the API developer docs section, you can allow users to swap NFTs, get NFT data, read the blockchain, upload files to decentralized storage, and much more';
const url = 'https://wakeuplabs.stoplight.io/docs/stoplight-platform/rbnrnmfi9fu1s-how-to-swap-two-nf-ts-step-by-step';
const pageTitle = 'SWAP';

const Marketplace: React.FC<FormProps> = () => {
  usePageTracking();
  
  return ( 
    <EmptyStateCardHorizontal 
      title={title}
      subtitle={subtitle}
      url={url}
      buttonText='Go to Swap'
      pageTitle={pageTitle} />
  );
};

export default Marketplace;
