// Material UI
import { useAuth0 } from '@auth0/auth0-react';
import { Badge, BadgeProps } from '@mui/material';
import { Box, styled } from '@mui/system';
import { useApiKey } from 'api/hooks/useApiKey';
import useRest from 'api/hooks/useRest';
import { Button, CustomCard, CustomText, Flex, Loading } from 'components/shared';
import { envKeys } from 'config';
import { IconCheck, KeySelected, NoteSelected } from 'Images';
// Components
// Images
import React from 'react';
import { useQuery } from 'react-query';
import { FontFamily, FontSize, FontWeight } from 'styles';
import { HTTPMethod } from 'utils/apiUtils';

import BaseStyles from './style';
import usePageTracking from 'hooks/usePageTracking';
// Crypto
// Internal
// Styles

export enum SubscriptionEnum {
  FREE = 'FREE',
  PREMIUM = 'PREMIUM',
} 

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    right: 38,
    top: -1,
    padding: '12px 16px',
  },
}));

const RowSubscription = ({ text }: { text:string }) => 
  <Flex row>
    <IconCheck height={24}/>
    <CustomText
      fontWeight={FontWeight.light}
      fontSize={FontSize.regular}
      style={{ flexDirection: 'row' }}>
      {text}
    </CustomText>
  </Flex>;

const FreeCard = (isFreeSubscription: boolean)=>
  <CustomCard size="big" style={{ ...BaseStyles.cardContainer, ...{} }}>
    <NoteSelected/>
    <CustomText 
      fontWeight={FontWeight.medium}
      fontSize={FontSize.xlarge}
      style={{ }}
    >
      FREE
    </CustomText>
    <CustomText 
      fontWeight={FontWeight.medium}
      fontSize={FontSize.regular}
      style={{ }}
    >
      $0/mo
    </CustomText>
    <Box sx={{ ...BaseStyles.checksContainer, marginTop: '24px', marginBottom: '14px' }}>
      <RowSubscription text='Create NFTs'/>
      <RowSubscription text='Retrieve NFT data'/>
      <RowSubscription text='Upload files to IPFS'/>
      <RowSubscription text='Token Gate API'/>
      <RowSubscription text='Swap NFT API'/>
    </Box >
    {isFreeSubscription && 
    <Button style={{ marginTop: '57px', borderColor: '#8158FC' }} 
      text='Subscribed'
      variant='outlined'
    />}
  </CustomCard>;

const PremiumCard = (isFreeSubscription:boolean) =>
  <CustomCard size="big" style={{ ...BaseStyles.cardContainer, ...{} }}>
    <KeySelected/>
    <CustomText 
      fontWeight={FontWeight.medium}
      fontSize={FontSize.xlarge}
      style={{ }}
    >
      PREMIUM
    </CustomText>
    <CustomText 
      fontWeight={FontWeight.light}
      fontSize={FontSize.regular}
      style={{ }}
    >
      Get all the <b>FREE</b> features, plus:
    </CustomText>
    <Box sx={{ ...BaseStyles.checksContainer }}>
      <RowSubscription text='Create Custom Collections'/>
      <RowSubscription text='Mint NFT on Mainnet!'/>
      <RowSubscription text='Get more storage'/>
      <RowSubscription text='Increase API Call limits'/>
      <RowSubscription text='Ask for custom features'/>
    </Box >
    {isFreeSubscription && <Button style={{ marginTop: '20px' }} 
      text='Upgrade Now'
      onClick={()=>window.open('https://form.jotform.com/WakeUp_Labs/upgrade-your-plan', '_blank')} 
    />}
  </CustomCard>;

const Subscription: React.FC = () => {
  const { isLoading: loadingData, data } = useApiKey({});
  usePageTracking();
  if (loadingData) {
    return (
      <Flex styles={{ marginTop: '32px' }} >
        <Loading />
      </Flex>
    );
  }
  const isFreeSubscription = (data as any)?.subscription !== SubscriptionEnum.PREMIUM;

  return ( 
    <Box padding='32px 0 0 0' width='fit-content' margin='auto'>
      <CustomText 
        fontWeight={700} 
        fontSize={FontSize.xxxxlarge} 
        style={{ alignSelf: 'flex-start', marginBottom: 12 }} 
        fontFamily={FontFamily.primary}>
        Subscription
      </CustomText>
      {isFreeSubscription ? 
        <Flex styles={{ flexDirection: 'row', gap: 24, alignItems: 'flex-start' }}>
          <StyledBadge color="primary" badgeContent='Subscribed'>
            {FreeCard(isFreeSubscription)}
          </StyledBadge>
          {PremiumCard(isFreeSubscription)}
        </Flex> :
        <Flex styles={{ flexDirection: 'row', gap: 24, alignItems: 'flex-start' }}>
          {FreeCard(isFreeSubscription)}
          <StyledBadge color="primary" badgeContent='Subscribed'>
            {PremiumCard(isFreeSubscription)}
          </StyledBadge>
        </Flex>
      }
    </Box>
  );
};

export default Subscription;
