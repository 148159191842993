// Material UI
// Components
// Images
import EmptyStateCardHorizontal from 'components/business/EmptyStateCardHorizontal';
import { envKeys } from 'config';
import usePageTracking from 'hooks/usePageTracking';
import React from 'react';
// Crypto
// Internal
// Styles

interface FormProps {}

// eslint-disable-next-line max-len
const title = 'We will redirect you to the API Docs section so you can tokenize assets';
const subtitle = 'In the API developer docs section you will be able to mint NFTs, ERC20 tokens, get data, read the blockchain, upload files to decentralized storage and much more.';
const url = envKeys.stoplight.minter;
const pageTitle = 'Tokenization';

const MintNFT: React.FC<FormProps> = () => {
  usePageTracking();
  
  return ( <EmptyStateCardHorizontal 
    title={title} 
    subtitle={subtitle}
    url={url}
    pageTitle={pageTitle}
    buttonText='Mint' /> 
  );
};

export default MintNFT;
