import { useAuth0 } from '@auth0/auth0-react';
import { IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/system';
import { useApiKey } from 'api/hooks/useApiKey';
import { useContract } from 'api/hooks/useContract';
import { CustomText, Flex, Loading } from 'components/shared';
import { IconCopy } from 'Images';
import { FontFamily, FontSize } from 'styles';
import BaseStyles from './style';
import { useDeployedERC20 } from 'api/hooks/useDeployedERC20';

export default function TableDeployedERC20() {
  const { isLoading: loadingDeployedContracts, data: deployedContracts } = useDeployedERC20({});
  const { isLoading: loadingApiKey, data: customerData } = useApiKey({});

  if ((loadingDeployedContracts || loadingApiKey) && !!customerData) {
    return (
      <Flex >
        <Loading />
      </Flex>
    );
  }
  
  return (
    <Box>
      <CustomText fontWeight={700} fontSize={FontSize.xxxxlarge} style={{ alignSelf: 'flex-start', marginBottom: '16px' }} fontFamily={FontFamily.primary}>
        Deployed ERC20 Contracts
      </CustomText>
      <TableContainer component={Paper} >
        <Table sx={{ minWidth: 650 }} >
          <TableHead>
            <TableRow>
              <TableCell >Contract Address</TableCell>
              <TableCell >Contract ID</TableCell>
              <TableCell>Chain</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Symbol</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customerData && (deployedContracts as Array<any>)?.map(row => (
              <TableRow key={row.contractAddress} >
                <TableCell component="th" scope="row">
                  {row.address}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.id}
                  <IconButton color='primary' sx={BaseStyles.iconButton} onClick={()=>{navigator.clipboard.writeText(row.id);}}>
                    <IconCopy />
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.chain}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.symbol}
                </TableCell>
              </TableRow>
            ))}
            {((deployedContracts as Array<any>)?.length === 0 || !customerData) && 
          <TableRow  >
            <TableCell component="th" scope="row" colSpan={6} sx={{ textAlign: 'center' }}>
              No deployed contracts found. Please Deploy a contract from te API to see all its information here
            </TableCell>
          </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}