// Shared Components
import { Box } from '@mui/system';
import { CustomCard, CustomText } from 'components/shared';
import { ArrowRight } from 'Images';
// Types
// Hooks
import React from 'react';
// Styles
import { FontSize } from 'styles';

// Utils

interface CardWithImageProps {
  number: number | string;
  title?: string;
  subtitle?: string;
}

const DashboardCardNumber: React.FC<CardWithImageProps> = ({ title, subtitle, number }) => {

  return (
    <CustomCard size="medium" style={{ padding: 20, gap: 12, display: 'flex', flexDirection: 'column', width: '240px', height: '130px' }}>
      <Box>
        <CustomText fontWeight={500} fontSize={FontSize.regular}>
          {title}
        </CustomText>
        {subtitle && 
          <CustomText style={{ color: '#A0A0A0' }} fontWeight={300} fontSize={FontSize.small}>
            {subtitle}
          </CustomText>
        }
      </Box>
      <Box height={300} display='flex' justifyContent='flex-end'>
        <ArrowRight style={{ alignSelf: 'end', marginBottom: '20px', marginRight: '6px' }}/>
        <CustomText style={{ color: '#52C917' }} fontWeight={500} fontSize={FontSize.xxxxlarge}>
          {number}
        </CustomText>
      </Box>
    </CustomCard>
  );
};

export default DashboardCardNumber;
