import { minHeightTobBar } from 'styles/constants';

const BaseStyles = {
  root: {
    width: '100%',
    minHeight: `calc(100vh - ${minHeightTobBar})`,
  },
  linkStyles: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  cardContainer: {
    padding: 24, 
    gap: 12,
    display: 'flex', 
    flexDirection: 'column',
    width: 'fit-content',
    alignSelf: 'flex-start', 
    alignItems: 'center',
    minWidth: 300,
    position: 'relative',
  },
  checksContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
  },
};

export default BaseStyles;
