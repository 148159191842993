// Shared Components
import { Box } from '@mui/system';
import { CustomCard, CustomText } from 'components/shared';
// Types
import Button from 'components/shared/Button';
import { EmptyStateDoc } from 'Images';
// Hooks
import React from 'react';
// Styles
import { Colors, FontFamily, FontSize } from 'styles';

// Utils

interface CardWithImageProps {
  title: string
  subtitle: string
  url?: string
  pageTitle: string
  onClick?:any
  buttonText?:string
}

const DashboardCard: React.FC<CardWithImageProps> = ({ title, subtitle, url, pageTitle, onClick, buttonText }) => {

  return (
    <Box paddingTop="32px" display='flex' width='fit-content' margin='auto' flexDirection='column' alignItems='center' >
      <CustomText fontFamily={FontFamily.primary} fontWeight={700} fontSize={FontSize.xxxxlarge} style={{ alignSelf: 'flex-start' }}>
        {pageTitle}
      </CustomText>
      <br/>
      <CustomCard size="big" style={{ marginTop: 32, gap: 12, display: 'flex',  minWidth: '300px', margin: 'auto', minHeight: '350px' }}>
        <Box sx={{ background: '#E0D9F3', display: 'flex', justifyContent: 'center', minWidth: '40%' }}>
          <EmptyStateDoc style={{ margin: 'auto' }} />     
        </Box>
        <Box p={5} display='flex' flexDirection='column' justifyContent='space-between'>
          <Box>
            <CustomText fontFamily={FontFamily.primary} fontWeight={700} fontSize={FontSize.xxlarge} style={{ color: Colors.blackLight }}>
              {title}
            </CustomText>
            <br/>
            <CustomText fontWeight={400} fontSize={FontSize.regular} style={{ color: Colors.black }}>{subtitle}</CustomText>
          </Box>
          <br/>
          <br/>
          <br/>
          <Button onClick={onClick ? onClick : ()=>window.open(url, '_blank')} sx={{ alignSelf: 'flex-end' }}  text={buttonText || 'Go to Docs'} />
        </Box>
      </CustomCard>
    </Box>
  );
};

export default DashboardCard;
