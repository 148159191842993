// Material UI
import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import { useApiKey } from 'api/hooks/useApiKey';
import { useAnalytics } from 'api/hooks/useAnalytics';
import DashboardCardNumber from 'components/business/DashboardCardNumber';
// Components
import DashboardCardPie from 'components/business/DashboardCardPie';
import EmptyStateCardHorizontal from 'components/business/EmptyStateCardHorizontal';
import { redirect } from 'components/routes/config';
import { Flex, Loading } from 'components/shared';
import { envKeys } from 'config';
// Images
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useContract } from 'api/hooks/useContract';
import BaseStyles from './style';
import usePageTracking from 'hooks/usePageTracking';
import { useDeployedERC20 } from 'api/hooks/useDeployedERC20';
import Login from 'pages/Login';
import { useAuth } from 'api/hooks/useAuth';
// Crypto
// Internal
// Styles

interface FormProps {}

const title = 'Create your free API Key';
// eslint-disable-next-line max-len
const subtitle = 'First of all, you need to create your api key for free. Then you can start using all of the WakeUp Platform’s solutions.';
const url = envKeys.stoplight.base;

const getSmartContractData = (deployedContracts:number, total?: number)=> [
  {
    'id': 'Smart Contracts Deployed',
    'label': 'SC Deployed',
    'value': deployedContracts,
    'color': '#5626EE',
  },
  {
    'id': 'Smart Contracts Left',
    'label': 'SC Left',
    'value': (total || 10) - deployedContracts,
    'color': '#E0D9F3',
  },
];

const Form: React.FC<FormProps> = () => {
  const navigate = useNavigate();
  const { isAuthenticated, user, isLoading } = useAuth0();
  
  const { isLoading: loadingApiKey, data } = useApiKey({});
  const { isLoading: loadingAnlytics, data: anlyticsData } = useAnalytics({ enabled: !!(data as any)?.apiKey });
  const { isLoading: loadingDeployedSM, data: deployedSM } = useContract({ enabled: !!(data as any)?.apiKey });
  const { isLoading: loadingDeployedContracts, data: deployedContracts } = useDeployedERC20({});

  usePageTracking();
  if (isLoading) {
    return <Loading />;
  }
  
  if (!isAuthenticated) {
    return <Login />;
  }
  
  if ( (data && (loadingDeployedContracts || loadingAnlytics || loadingDeployedSM))) {
    return (
      <Flex styles={{ marginTop: '60px' }}>
        <Loading />
      </Flex>
    );
  }
  
  if (user?.email && !data && !loadingApiKey) {
    return (<EmptyStateCardHorizontal 
      title={title}
      subtitle={subtitle}
      url={url}
      pageTitle={`Welcome, ${user?.name}!`}
      buttonText='Create API Key'
      onClick={()=>navigate(redirect.getApiKey('true'))} 
    />);
  }
  const yearMonth = `${new Date().getFullYear()}.${new Date().getMonth()}`;
  const uploadedNumber = (anlyticsData as unknown as Array<any>)?.find(e=>e.action === 'upload' && yearMonth === e.yearMonth)?.numberOfCalls || 0;
  const listByAddressNumber = (anlyticsData as unknown as Array<any>)?.find(e=>e.action === 'token-list' && yearMonth === e.yearMonth)?.numberOfCalls || 0;
  const getOneByTokenNumber = (anlyticsData as unknown as Array<any>)?.find(e=>e.action === 'token-one' && yearMonth === e.yearMonth)?.numberOfCalls || 0;
  const deployedErc20Mumbai = deployedContracts?.filter((c:any)=> c.chain === 'Polygon_Mumbai');
  const deployedErc20Mainnet = deployedContracts?.filter((c:any)=> c.chain === 'Polygon');

  return (<>
    {data && !loadingAnlytics && anlyticsData && 
        <Box padding="62px" 
          sx={BaseStyles.container}>
          <Box display='flex' gap='20px' flexWrap='wrap' justifyContent='center'>
            <DashboardCardPie title="Minted NFTs" data={anlyticsData}/>
            <DashboardCardPie
              title="Deployed Smart Contracts"
              data={getSmartContractData((deployedSM as any)?.length || 0)}
              parsed 
            />
            <DashboardCardPie
              title="Deployed ERC20 Contracts Mumbai"
              data={getSmartContractData((deployedErc20Mumbai as any)?.length || 0, 5)}
              parsed 
            />
            <DashboardCardPie
              title="Deployed ERC20 Contracts Polygon"
              data={getSmartContractData((deployedErc20Mainnet as any)?.length || 0, 1)}
              parsed 
            />
          </Box>
          <Box display='flex' flexDirection='row' gap='20px' width='100%' justifyContent='center'>
            <DashboardCardNumber title="Uploaded Files" number={uploadedNumber} subtitle='Used this month' />
            <DashboardCardNumber title="List NFTs API calls" number={listByAddressNumber} subtitle='Used this month' />
            <DashboardCardNumber title="Token info API calls" number={getOneByTokenNumber} subtitle='Used this month' />
            <DashboardCardNumber
              title="Total API Calls"
              number={listByAddressNumber + getOneByTokenNumber}
              subtitle='Used this month'
            />
          </Box>
        </Box>
    }
  </>
  );

};

export default Form;
