import { minHeightTobBar } from 'styles/constants';

const BaseStyles = {
  root: {
    width: '100%',
    minHeight: `calc(100vh - ${minHeightTobBar})`,
  },
  linkStyles: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  container: { 
    display: 'flex', 
    gap: '20px', 
    flexDirection: 'column', 
    alignItems: 'center', 
    justifyContent: 'center', 
  },
};

export default BaseStyles;
