import { useAuth0 } from '@auth0/auth0-react';
import MercadoPagoConnect from './MercadoPagoConnect';
import { mercadoPagoRepository } from './mercadoPago';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { envKeys } from 'config';

const Checkout = () => {

  //const query = useQuery();

  const [params] = useSearchParams();

  const { isAuthenticated, user } = useAuth0();

  console.log('Chekcout', isAuthenticated, params);

  const code = params.get('code') as string;
  const email = user?.email || params.get('userID') as string;

  console.log({ user, email });

  const [tokenResult, setTokenResult] = useState<Record<string, string> | null>(null);

  useEffect(()=> {
    if (!code || !isAuthenticated) return;

    const action = async () => {
      const token = await mercadoPagoRepository.getToken({
        code: code,
        userId: email,
        baseUrl: envKeys.payments.api!,
      });

      setTokenResult(token);
    };

    action();
  }, [email, code, isAuthenticated]);

  if (!isAuthenticated) {
    return <div>Loading...</div>;
  }

  const authenticationConfig = {
    baseUrl: 'https://auth.mercadopago.com/authorization',
    clientId: process.env.REACT_APP_CLIENT_ID!,
    redirectUri: process.env.REACT_APP_REDIRECT_CHECKOUT!,
    userId: email,
  };
  //TODO: delete
  console.log(authenticationConfig);

  return (
    <div className="flex-col gap-4 p-20">
      <MercadoPagoConnect
        mercadoPagoRepository={mercadoPagoRepository}
        authenticationConfig={authenticationConfig}
        token={tokenResult}
      />
    </div>
  );
};

export default Checkout;
