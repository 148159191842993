import Router from 'components/routes/Router/Router';
import React from 'react';

import { routes } from './routes';

const Subscription: React.FC = () => {
  return (
    <Router routes={routes} />
  );
};

export default Subscription;
